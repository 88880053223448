import './App.css';
import { AmplifyAuthenticator, withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import Amplify from 'aws-amplify';
//import aws_exports from './aws-exports';

//Amplify.configure(aws_exports);

function App() {
  return (
    {/* <AmplifyAuthenticator> */},
    <div className="App">
      <header className="App-header">
      <img src={'/android-chrome-192x192.png'} className="App-logo" alt="logo" />
      </header>
    </div>
  )
}

export default (App);
